import { Container, Row, Col, Card, Button, Form, Modal, InputGroup } from 'react-bootstrap'
import {getAuth} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { collection, getDocs, query, limit, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import {getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject} from 'firebase/storage'
import {db} from '../../firebase.config'
import {v4 as uuidv4} from 'uuid'
import { Icon } from '@iconify/react';
import Spinner from '../../components/Spinner'
import { toast } from 'react-toastify'

const Agency = () => {
    const [agencies, setAgencies] = useState<any>(undefined)
    const [filteredAgencies, setFilteredAgencies] = useState<any>(undefined)
    const [loading, setLoading] = useState(true)
    const [clearFilter, setClearFilter] = useState(true)
    const [text, setText] = useState('')
    const [imgLoc, setImgLoc] = useState('')
    // const [lastFetchedAgencies, setLastFetchedAgencies] = useState<any>(undefined)
    const [selectedAgency, setSelectedAgency] = useState<any>(undefined)
    const [createAgency, setCreateAgency] = useState<any>(undefined)
    const [showEdit, setshowEdit] = useState(false);
    const [showCreate, setshowCreate] = useState(false);
    const [deleteAgency, setDeleteAgency] = useState<any>(undefined)
    const [showDelete, setShowDelete] = useState(false)

    const auth = getAuth()

    useEffect( () => {
        const fetchAgencies = async () => {
            try {
                // get agencies reference
                const agenciesRef = collection(db, 'agency')
                
                //create query
                const q = query(agenciesRef, limit(10))

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedAgencies(lastVisible)

                let agency:any = []

                querySnap.forEach( (doc) => {
                    return agency.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setAgencies(agency)
                setLoading(false)


            }catch(err){
                console.log(err)
            }
        }
        fetchAgencies()
    },[showEdit, showCreate, showDelete])

    const handleCloseEdit = () => {
        setshowEdit(false)
    }
    const handleshowEdit = (obj:any) => {
        setImgLoc(obj.data.storageRef)
        setSelectedAgency(obj)
        setshowEdit(true)
    }

    const handleCloseCreate = () => {
        setshowCreate(false)
    }
    const handleshowCreate = () => {
        // setSelectedEvent(event)
        setCreateAgency({
            'id': '', 
            'data': {
                'title': '',
                'subTitle': '',
                'description': '',
                'socMed': '',
                'website': '',
                'email': '',
                'contact': '',
        }})
        setshowCreate(true)
    }


    const onSubmit = async (e:any) => {
        e.preventDefault()
    
        setLoading(true)
        let str
    
        // Store image in firebase
        const storeImage = async (image:any) => {
            return new Promise((resolve, reject) => {
            const storage = getStorage()
            const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
    
            const storageRef = ref(storage, 'images/' + fileName)
    
            const uploadTask = uploadBytesResumable(storageRef, image, image.type)
    
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log('Upload is ' + progress + '% done')
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused')
                    break
                  case 'running':
                    console.log('Upload is running')
                    break
                  default:
                    break
                }
              },
              (error) => {
                reject(error)
              },
              () => {
                // store image firestore location
                str = uploadTask.snapshot.ref.toString().split('/')

                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL)
                })
              }
            )
          })
        }
        let imageUrls:any

        if (selectedAgency.images){
            imageUrls = await Promise.all(
                [...selectedAgency.images].map((image) => storeImage(image))
              ).catch(() => {
                setLoading(false)
                toast.error('Image not uploaded')
                return
              })
        }


        const selectedAgencyCopy = {
            ...selectedAgency,
        }
        
        const getUsername = auth.currentUser?.email?.split('@')
        const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

        selectedAgencyCopy.data.imgCover = !imageUrls ? selectedAgencyCopy.data.imgCover : imageUrls
        selectedAgencyCopy.data.modifiedBy = getUsername![0]
        selectedAgencyCopy.data.storageRef = !imageUrls ? (selectedAgency.data.storageRef ? selectedAgency.data.storageRef : '') : str[str.length - 1]
        selectedAgencyCopy.data.website = withHttp(selectedAgencyCopy.data.website)
        delete selectedAgencyCopy.id
        delete selectedAgencyCopy.images

    
        // update listing
        const docRef = doc(db, 'agency', selectedAgency.id)
        await updateDoc(docRef,selectedAgencyCopy.data)
        

        const storage = getStorage();

        // Create a reference to the file to delete
        const desertRef = ref(storage, `images/${imgLoc}`);
  
        // Delete the file
        await deleteObject(desertRef).catch((err) => console.log(err))
        toast.success('Agency updated')

        setLoading(false)
        setshowEdit(false)
      }


    const onSubmitCreate = async (e:any) => {
      e.preventDefault()
      setLoading(true)
      let str
      // Store image in firebase
      const storeImage = async (image:any) => {
          return new Promise((resolve, reject) => {
          const storage = getStorage()
          const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
  
          const storageRef = ref(storage, 'images/' + fileName)
  
          const uploadTask = uploadBytesResumable(storageRef, image, image.type)
  
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
                  break
                default:
                  break
              }
            },
            (error) => {
              reject(error)
            },
            () => {
              // store image firestore location
              str = uploadTask.snapshot.ref.toString().split('/')
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL)
              })
            }
          )
        })
      }
      let imageUrls:any

      if (createAgency.images){
          imageUrls = await Promise.all(
              [...createAgency.images].map((image) => storeImage(image))
            ).catch(() => {
              setLoading(false)
              toast.error('Images not uploaded')
              return
            })
      }

      const createAgencyCopy = {
          ...createAgency,
      }
      
      const getUsername = auth.currentUser?.email?.split('@')
      const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
      


      createAgencyCopy.data.imgCover = !imageUrls ? createAgencyCopy.data.imgCover : imageUrls
      createAgencyCopy.data.modifiedBy = getUsername![0]
      createAgencyCopy.data.createdBy = getUsername![0]
      createAgencyCopy.data.storageRef = !imageUrls ? (createAgencyCopy.data.storageRef ? createAgencyCopy.data.storageRef : '') : str[str.length - 1]
      createAgencyCopy.data.website = withHttp(createAgencyCopy.data.website)
      delete createAgencyCopy.id 
      delete createAgencyCopy.images



      const docRef = await addDoc(collection(db, 'agency'), createAgencyCopy.data)
      setLoading(false)
      setshowCreate(false)

      toast.success('Agency added')

    }
    
    const onMutate = (e:any) => {
    
        // Files
        if(e.target.files) {
            setSelectedAgency((prevState:any) => ({
            ...prevState,
            images: e.target.files
          }))
        }


        // Text/Boolean/Numbers
        if(!e.target.files) {
            setSelectedAgency((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data, [e.target.id]: e.target.value},
          } ))
        }
    }

    const onMutateCreate = (e:any) => {
    
        // Files
        if(e.target.files) {
            setCreateAgency((prevState:any) => ({
            ...prevState,
            images: e.target.files
          }))
        }

        // Text/Boolean/Numbers
        if(!e.target.files) {
            setCreateAgency((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data, [e.target.id]: e.target.value},
          } ))
        }
    }

    const handleCloseDelete = () => {
      setShowDelete(false)
    }

    const handleShowDelete = (agency:any) => {
      setImgLoc(agency?.data?.storageRef)
      setDeleteAgency(agency)
      setShowDelete(true)
    }

    const onDelete = async (e:any) =>{
      e.preventDefault()
      const storage = getStorage();

        // Create a reference to the file to delete
      const desertRef = ref(storage, `images/${imgLoc}`);

      await deleteDoc(doc(db, "agency", deleteAgency.id));

      // Delete the file
      await deleteObject(desertRef).catch((err) => console.log(err))
      
      
      setShowDelete(false)
    }

    const handleFilterSearch = (e) => {
      e.preventDefault()

      setText(e.target.value)
      setClearFilter(false)
      const filtered = agencies.filter(obj => {
        return obj.data.title.toLowerCase().match(e.target.value);
      });

      setFilteredAgencies(filtered)
    }

    if(loading){
      return <Spinner/>
    }

  return (
  <>
        <Container>
            <Row>
                <Col>
                <InputGroup style={{justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                    <Form.Control
                    placeholder="Search title"
                    aria-label="Search title"
                    value={text}
                    onChange={handleFilterSearch}
                    />
                    <Button onClick={() => handleshowCreate()}  variant="outline-secondary" style={{padding: '7px', margin: '0'}}><Icon icon="icon-park-outline:add" className="text-primary" style={{fontSize:"25px"}}/></Button>
                </InputGroup>
                </Col>
            </Row>
            
            <Row style={{marginTop: '10px'}}>
                {clearFilter && agencies?.map( (agency: any, key: any) => {
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={agency.data.imgCover} height={200} width={350} style={{objectFit: 'cover'}}/>
                        <Card.Body>
                            {agency.data.title && <Card.Title>{agency.data.title}</Card.Title>}
                            {agency.data.subTitle && <Card.Text style={{margin: 0}}><b>Sub Title: </b> {agency.data.subTitle}</Card.Text>}
                            {agency.data.description && <Card.Text ><b>Description:</b> {agency.data.description}</Card.Text>}
                            {agency.data.socMed && <Card.Text style={{margin: 0}}><b>Social Media: </b> {agency.data.socMed}</Card.Text>}
                            {agency.data.website && <Card.Text style={{margin: 0}}><b>Website: </b> {agency.data.website}</Card.Text>}
                            {agency.data.email && <Card.Text style={{margin: 0}}><b>Email: </b> {agency.data.email}</Card.Text>}
                            {agency.data.contact && <Card.Text style={{margin: 0}}><b>Contact: </b> {agency.data.contact}</Card.Text>}
                            {agency.data.createdBy && <Card.Text style={{margin: 0}}><b>Created by:</b> {agency.data.createdBy}</Card.Text>}
                            {agency.data.modifiedBy && <Card.Text><b>Modified by:</b> {agency.data.modifiedBy}</Card.Text>}
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                          <Button variant="primary" onClick={() => handleshowEdit(agency)} >Edit</Button>
                          <Button variant="danger" onClick={() => handleShowDelete(agency)} >Delete</Button>
                        </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
                })}

                {!clearFilter && filteredAgencies?.map( (agency: any, key: any) => {
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={agency.data.imgCover} height={200} width={350} style={{objectFit: 'cover'}}/>
                        <Card.Body>
                            {agency.data.title && <Card.Title>{agency.data.title}</Card.Title>}
                            {agency.data.subTitle && <Card.Text style={{margin: 0}}><b>Sub Title: </b> {agency.data.subTitle}</Card.Text>}
                            {agency.data.description && <Card.Text ><b>Description:</b> {agency.data.description}</Card.Text>}
                            {agency.data.socMed && <Card.Text style={{margin: 0}}><b>Social Media: </b> {agency.data.socMed}</Card.Text>}
                            {agency.data.website && <Card.Text style={{margin: 0}}><b>Website: </b> {agency.data.website}</Card.Text>}
                            {agency.data.email && <Card.Text style={{margin: 0}}><b>Email: </b> {agency.data.email}</Card.Text>}
                            {agency.data.contact && <Card.Text style={{margin: 0}}><b>Contact: </b> {agency.data.contact}</Card.Text>}
                            {agency.data.createdBy && <Card.Text style={{margin: 0}}><b>Created by:</b> {agency.data.createdBy}</Card.Text>}
                            {agency.data.modifiedBy && <Card.Text><b>Modified by:</b> {agency.data.modifiedBy}</Card.Text>}
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                          <Button variant="primary" onClick={() => handleshowEdit(agency)} >Edit</Button>
                          <Button variant="danger" onClick={() => handleShowDelete(agency)} >Delete</Button>
                        </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
                })}
                
            </Row>
        </Container>

        <Modal {...selectedAgency} show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card.Img variant="top" src={selectedAgency?.data.imgCover} />
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Event Cover Image</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutate}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={selectedAgency?.data.title}
                onChange={onMutate}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="subTitle">
                <Form.Label>Sub Title</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedAgency?.data?.subTitle}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="description"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={4} 
              value={selectedAgency?.data.description}
              onChange={onMutate}
              required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="socMed">
                <Form.Label>Social Media</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedAgency?.data.socMed}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedAgency?.data.website}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedAgency?.data.email}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="contact">
                <Form.Label>Contact</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedAgency?.data.contact}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} >
            Save Changes
          </Button>
        </Modal.Footer>
        {loading && <Spinner/>}
      </Modal>


      <Modal {...createAgency} show={showCreate} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Add Agency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Event Cover Image</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutateCreate}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={createAgency?.data.title}
                onChange={onMutateCreate}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="subTitle">
                <Form.Label>Sub Title</Form.Label>
                <Form.Control
                    type="text"
                    value={createAgency?.data?.subTitle}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="description"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={4} 
              value={createAgency?.data.description}
              onChange={onMutateCreate}
              required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="socMed">
                <Form.Label>Social Media</Form.Label>
                <Form.Control
                    type="text"
                    value={createAgency?.data.socMed}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                    type="text"
                    value={createAgency?.data.website}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="text"
                    value={createAgency?.data.email}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="contact">
                <Form.Label>Contact</Form.Label>
                <Form.Control
                    type="text"
                    value={createAgency?.data.contact}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreate}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmitCreate} >
            Save Changes
          </Button>
        </Modal.Footer>
        {loading && <Spinner/>}
      </Modal>


      <Modal {...deleteAgency} show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Agency</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onDelete}>
        <Modal.Body>
            <p>Are you sure you want to delete <b>{deleteAgency?.data.title}</b></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" type='submit'>
            Delete
          </Button>
        </Modal.Footer>
        </Form>
        {loading && <Spinner/>}
      </Modal>
    </>
  )
}

export default Agency