import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBv4mTIxmd4sk7RP9IO1FDYQ0r_ck2O0vA",
    authDomain: "mixedupentprod-api.firebaseapp.com",
    projectId: "mixedupentprod-api",
    storageBucket: "mixedupentprod-api.appspot.com",
    messagingSenderId: "752463514719",
    appId: "1:752463514719:web:42c6fcff60339c81809fea",
    measurementId: "G-JR2Y27EBCD"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore()