import { useState } from "react";
import PrivateRoute from "../../components/PrivateRoute"
import Background from "../../components/Background"
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoDark from '../../assets/images/logo-dark.png'
import Events from "../admin/Events";
import Agency from "../admin/Agency";
import Members from "../admin/Members";
import Cover from "../admin/Cover";

const Dashboard = () => {
  const [page, setPage] = useState('events')

  return <>
  <PrivateRoute/>
  <Background>
    <div className="dashboard-container"  style={{overflow: 'auto'}}>

    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
      <Navbar.Brand href="/">
              <img
                src={logoDark}
                width="auto"
                height="50px"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={()=> setPage('events')}>Events</Nav.Link>
            <Nav.Link onClick={()=> setPage('agency')}>Agency</Nav.Link>
            <Nav.Link onClick={()=> setPage('members')}>Members</Nav.Link>
            <Nav.Link onClick={()=> setPage('cover')}>Cover</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


      {(() => {
        if (page==='events') {
          return <Events/>;
        } 
        if (page==='agency') {
          return <Agency/>;
        } 
        if (page==='members') {
          return <Members/>;
        } 
        if (page==='cover') {
          return <Cover/>;
        } 
      })()}
      {/* <Events/> */}
    </div>
  </Background>

  </>
}

export default Dashboard