import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import serviceImg from '../assets/images/services-img.png'
import { Icon } from '@iconify/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

const items = [
    {
        id: 1,
        service: "CLUB NIGHTS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",

    },
    {
        id: 2,
        service: "ARTIST MANAGEMENT",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",

    },
    {
        id: 3,
        service: "CONCERTS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 4,
        service: "PRIVATE GIGS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 5,
        service: "AUDIO VISUAL EQUIPMENT RENTAL",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 6,
        service: "CATERING & LIVE BAND",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 7,
        service: "WEDDING & BIRTHDAY EVENTS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 8,
        service: "PRODUCT LAUNCHES",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 9,
        service: "EXHIBITIONS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 10,
        service: "AWARD CEREMONIES",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 11,
        service: "FASHION SHOW",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 12,
        service: "HOSTESSES",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 13,
        service: "DANCE CREW",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 14,
        service: "CORPORATE EVENT",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 15,
        service: "GRAPHIC DESIGN & VIDEO EDITING",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 16,
        service: "PERFORMANCE ARTS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 17,
        service: "PROMOTERS",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },
    {
        id: 18,
        service: "VIDEO COVERAGE",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, rerum.",
    },

];

const Services = () => {
  return (
   <React.Fragment>
    <section className="section bg-light" id="services">
  <Container>
    <Row className="justify-content-center">
      <Col lg={8}>
        <div className="text-center title mb-5">
          <h3 className="mb-3 mt-5">Products and Services</h3>
          <div className="title-icon position-relative">
            <div className="position-relative">
              <Icon icon="uim-arrow-circle-down" className='text-primary' style={{fontSize: "25px"}}></Icon>
            </div>
          </div>
        </div>
      </Col>
    </Row>
   

    <Row className="align-items-center">
    <Col lg={6} ms={{ lg: 'auto' }} sm={8} order={{ sm: 'first' }}>
        <div>
          <Image src={serviceImg} alt="" fluid className="mx-auto d-block" />
        </div>
      </Col>
      <Col lg={5} order={{ sm: 'last' }}>
        <div className="mt-4 mt-lg-0">
          <h4>Create. Engage. Views!</h4>
          <p className="text-muted mt-3">Level up your content creation with our products and services.</p>

          <div>
            <Swiper slidesPerView={8}
                                autoHeight={true}
                                direction={"vertical"}
                                pagination={{ clickable: true}}
                                modules={[Pagination, Autoplay]}
                                mousewheel={true}
                                // allowTouchMove={false}
                                // loop={true}
                                autoplay={{ delay: 2000, disableOnInteraction: false }}
                                className="client-swipers">
                
                {items.map((item:any, key:any)=> (
                    <SwiperSlide key={key} >
                        <p className="mb-2 text-muted">
                            <span className="uim-icon-info me-2 align-middle"><Icon icon="uim-check-circle" style={{color:"#6BBE09", fontSize:"25px"}}></Icon></span>{item.service}
                        </p>
                    </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className="mt-4">
            <Link to="#" className="text-primary">Learn more..</Link>
          </div>
        </div>
      </Col>

      
    </Row>
    {/* end row */}

    <hr className="my-5" />
    
  </Container>
  
</section>

   </React.Fragment>

   
  )
}

export default Services