import { Col, Container, Row, Button, Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { collection, getDocs, getDoc, query, where, doc } from 'firebase/firestore'
import {db} from '../firebase.config'
import Spinner from './Spinner'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import Footers from '../Layout/Footer'
import styled from 'styled-components'
import Features from './Features'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom'

const Wrapper = styled.div<{link?: string, link2?: string}>`

    background: url(${({link}) => link});
    position: absolute;
    left: 0;
    width: 100%;
    height: 90vh;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -95vh;

    @media (max-width: 700px){
        background: url(${({link2}) => link2});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

`

const Agency = () => {
    const [agency, setAgency] = useState<any>(undefined)
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [currentAgency, setCurrentAgency] = useState<any>([])
    const [agencyMembers, setAgencyMembers] = useState<any>([])
    const [coverWeb, setCoverWeb] = useState<any>(undefined)
    const [coverMobile, setCoverMobile] = useState<any>(undefined)

    const [id, setId] = useState<any>('')

    const regLink = 'https://docs.google.com/spreadsheets/d/1UFafN1XWnQ-rBUeW6jPHoKHddoRud3G8HIBJyueIiW0/edit?resourcekey#gid=1898377'

    const handleClose = () => setShow(false);
    const handleShow = (id:any) => {
        setId(id)
        setShow(true);
    }

    useEffect( () => {
        const fetchAgencyData = async () => {
            setLoading(true)
            try {
                const querySnap = await getDoc(doc(db, 'agency', id))
                setCurrentAgency(querySnap.data())
            } catch (error) {
                console.log(error)
            }
            
        }

        
        fetchAgencyData()
        
    },[id])

    useEffect( () => {
        const fetchAgencyMembers = async () => {
            try {
                const memberRef = collection(db, 'member')
                const q = query(memberRef, where('agency', '==', id))

                const qSnap = await getDocs(q)

                const members:any = []

                qSnap.forEach((doc) => {
                    return members.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setAgencyMembers(members)
                
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        fetchAgencyMembers()
    },[id])

    useEffect( () => {
        const fetchAgency = async () => {
            try {
                // get events reference
                const agencyRef = collection(db, 'agency')
                
                //create query
                const q = query(agencyRef)

                //execute query
                const querySnap = await getDocs(q)

                let agency:any = []

                querySnap.forEach( (doc) => {
                    return agency.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setAgency(agency)
                setLoading(false)


            }catch(err){
                console.log(err)
            }
        }
        fetchAgency()
    },[])

    useEffect( () => {
        setLoading(true)
        const fetchCovers = async () => {
            try {
                // get events reference
                const eventsRef = collection(db, 'covers')
                
                //create query
                const q = query(eventsRef)

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedEvents(lastVisible)

                let cover:any = []

                querySnap.forEach( (doc) => {
                    return cover.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setCoverWeb(cover.filter(obj => {
                    return obj?.data?.location.match('tiktok web')
                } ))

                // console.log(coverWeb[0]?.data?.imgUrl[0])


                setCoverMobile(cover.filter(obj => {
                    return obj?.data?.location.match('tiktok mobile')
                } ))

                setLoading(false)

                // console.log(events)

            }catch(err){
                console.log(err)
            }
        }
        fetchCovers()
    },[])


  return (
    <>
     <Container fluid="sm">
        {coverWeb && coverMobile && <Wrapper link={coverWeb[0]?.data?.imgUrl[0]} link2={coverMobile[0]?.data?.imgUrl[0]}/>}
        {/* <div className="event-hero-1-bg" style={{marginTop: '-95vh'}}>
        </div> */}
    </Container>

    <p></p>

    {loading ? <Spinner/> : agency && agency.length > 0 &&
        <>
        <Container style={{marginTop: '95vh'}}>
            <Row>
                <Col>
                <Features/>
                </Col>
            </Row>
            <Row className="justify-content-center">
            <Col lg={8}>
                <div className="text-center title mb-5">
                <h3 className="mb-3">Agency Partner</h3>
                <div className="title-icon position-relative">
                    <div className="position-relative">
                    <Icon icon="uim-arrow-circle-down" className='text-primary' style={{fontSize: "25px"}}></Icon>
                    </div>
                </div>
                </div>
            </Col>
            </Row>
            <Row>
                <Col>
                <Swiper slidesPerView={1}
                                pagination={{ clickable: true}}
                                modules={[Pagination, Autoplay]}
                                spaceBetween={40}
                                // centeredSlides={true}
                                loop={true}
                                // freeMode={true}
                                autoplay={{ delay: 2500, disableOnInteraction: false }}
                                className="client-swiper"
                                breakpoints={{
                                    640: {
                                      slidesPerView: 1,
                                    },
                                    768: {
                                      slidesPerView: 3,
                                    }
                                  }}>

                                    

                                {agency.map((agency:any, key:any)=>(
                                    
                                <SwiperSlide key={key} className="client-slider">
                                    <Button style={{height: '250px', width: '100%', background: `url(${agency.data.imgCover}) center center`, borderColor: 'unset', backgroundRepeat: 'no-repeat'}} 
                                    onClick={ (e) => {e.preventDefault(); handleShow(agency.id)}}/>
                                </SwiperSlide>
                                ))}
                            </Swiper>
                </Col>
                
            </Row>
            <Row>
                <Col style={{justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h4 className="text-muted mt-3">Want to be part of us? Register your talent agency here.</h4>
                    {/* <p style={{display: 'flex'}}  > </p> */}
                    <Link to={regLink} target="_blank" rel="noopener noreferrer">
                        <Button>Register now</Button>
                    </Link>
                </Col>
            </Row>
        </Container>

        < br/>
        < br/>
        < br/>
        <Footers/>



        <Modal {...agencyMembers} show={show} onHide={handleClose} size="lg" scrollable={true} >
            <Modal.Header closeButton>
                <Modal.Title>Agency Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} md={4}>
                            <img src={currentAgency?.imgCover} alt={currentAgency?.title} style={{height: '250px', width: '100%', objectFit: 'scale-down'}}/>
                        </Col>
                        <Col xs={12} md={8} style={{marginTop: '1rem'}}>
                            <h3>{currentAgency?.title}</h3>
                            <p>{currentAgency?.subTitle}</p>
                            <p>{currentAgency?.description}</p>
                            <br />
                            <p style={{margin: 0}}>SocMed: {currentAgency?.socMed}</p>
                            <p style={{margin: 0}}>Website: {currentAgency?.website}</p>
                            <p style={{margin: 0}}>Email: {currentAgency?.email}</p>
                            <p style={{margin: 0}}>Contact: {currentAgency?.contact}</p>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1rem'}}>
                    {agencyMembers.map( (agencyMember:any, key:any) =>{
                        return(
                        <Col xs={12} md={4} key={key}>
                            <Row>
                                <Col md={4}>
                                    <img src={agencyMember.data?.img} alt={agencyMember.data?.name} style={{height: '70px', width: '100%', objectFit: 'cover'}}/>
                                </Col>
                                <Col md={8}>
                                    <h4 style={{margin: 0}}>{agencyMember.data?.name}</h4>
                                    <p style={{margin: 0}}>{agencyMember.data?.followerCount} followers</p>
                                    <p style={{margin: 0}}>{agencyMember.data?.ign}</p>
                                </Col>
                            </Row>
                        </Col>
                        )
                        })
                    }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>}
    </>
  )
}


export default Agency