import { Icon } from '@iconify/react';
import React, { useState } from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

const Contact = () => {
  const [mail, setMail] = useState({
    'email': '',
    'name': '',
    // 'senderEmail': '',
    'subject': '',
    'message': '',
  })

  function sendMail() {
    var link = "mailto:admin@mixedupentprod.com"
             + "&subject=" + encodeURIComponent(`${mail.subject} from ${mail.name}`)
             + "&body=" + encodeURIComponent(mail.message)
    ;
    
    window.location.href = link;
}

const onMutate = (e:any) => {
    e.preventDefault()
  // Files

    setMail((prevState:any) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))

}
  return (
    <React.Fragment>
        <section id="contact">
  <Container>
    <Row>
      <Col lg={12}>
        <Card className="contact-section-card mb-0">
          <Card.Body className="p-md-5">
            <div className="text-center title mb-5">
              <p className="text-muted text-uppercase fw-normal mb-2">Contact</p>
              <h3 className="mb-3">Have any Questions?</h3>
              <div className="title-icon position-relative">
                <div className="position-relative">
                  <Icon icon="uim-arrow-circle-down" className="text-primary" style={{fontSize:"25px"}}></Icon>
                </div>
              </div>
            </div>

            
            <Form onSubmit={sendMail}>
              <p id="error-msg"></p>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control name="name" id="name" type="text" placeholder="Enter your name..." onChange={onMutate}/>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control name="email" id="email" type="email" placeholder="Enter your email..." onChange={onMutate}/>
                  </Form.Group>
                </Col>
              </Row>
           

              <Form.Group className="mb-3">
                <Form.Label htmlFor="subject">Subject</Form.Label>
                <Form.Control name="subject" id="subject" type="text" placeholder="Enter Subject..." onChange={onMutate}/>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="message">Message</Form.Label>
                <Form.Control name="message" id="message" as="textarea" rows={3} placeholder="Enter your message..." />
              </Form.Group>

              <div className="text-end">
                <Button type="submit" id="submit" name="send" className="submitBnt btn btn-primary">
                  Send message
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    
  </Container>
  
</section>

    </React.Fragment>
  )
}

export default Contact