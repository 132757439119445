import { Col, Container, Row, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { collection, getDocs, getDoc, query, orderBy, doc } from 'firebase/firestore'
import EventThumbnail from './EventThumbnail'
import {db} from '../firebase.config'
import Spinner from './Spinner'
import Footers from '../Layout/Footer'


const EventDetail = () => {
    const [events, setEvents] = useState<any>(undefined)
    const [loading, setLoading] = useState(true)
    // const [lastFetchedEvents, setLastFetchedEvents] = useState<any>(undefined)

    const [currentEvent, setCurrentEvent] = useState<any>(undefined)
    const { id } = useParams() 

    useEffect(() => {
        const getCurrentEvent = async () => {
            const querySnap = await getDoc(doc(db, 'events', `${id}`))
            setCurrentEvent(querySnap.data())
        }
        getCurrentEvent()
    },[id])

    useEffect( () => {
        const fetchEvents = async () => {
            try {
                setLoading(true)
                // get events reference
                const eventsRef = collection(db, 'events')
                
                //create query
                const q = query(eventsRef, orderBy('date', 'desc'))

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedEvents(lastVisible)

                let events:any = []

                querySnap.forEach( (doc) => {
                    return events.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                // setEvents(events)
                setEvents(events.filter(item => item.id !== id))
                // console.log(events)

            }catch(err){
                console.log(err)
            }
        }
        fetchEvents()
        setLoading(false)
    },[id])

   

    //pagination /Load More
    // const onFetchMoreEvents = async () => {
    //     try{
    //         setLoading(true)

    //         // Get Reference
    //         const eventsRef = collection(db, 'events')

    //         //create query
    //         const q = query(
    //             eventsRef, 
    //             orderBy('date', 'desc'), 
    //             startAfter(lastFetchedEvents),
    //             limit(10)
    //             )
            
    //         // execute query
    //         const querySnap = await getDocs(q)
    //         const lastVisible = querySnap.docs[querySnap.docs.length - 1]

    //         const events:any[] = []

    //         querySnap.forEach((doc) => {
    //             return events.push({
    //                 id:doc.id,
    //                 data: doc.data(),
    //             })
    //         })

    //         setEvents( (prevState:any) => [...prevState, ...events])
    //         setLoading(false)

    //     }catch(err){
    //         console.log(err)
    //     }
    // }

    if(loading){
        return <Spinner/>
    }

    return <>  

        <Container fluid="sm">
            <div className="event-hero-1-bg" style={{background:`url(${currentEvent?.imgUrl}) center no-repeat`  , justifyContent: 'center', display: 'flex', alignItems: 'center', backgroundSize: 'cover',}}>
                {currentEvent?.videoUrl ? (<iframe title='video' height='90%' width='90%' src={currentEvent?.videoUrl}/>) : 
                <iframe id='poster' title='poster' className='iframe-poster' src={currentEvent?.posterLink} width={450} height={450} style={{display: 'flex', justifyContent: 'center',border:'none',overflow:'hidden'}} allowFullScreen={true} loading='eager' sandbox='allow-scripts' allow="autoplay; clipboard-write; encrypted-media; picture-in-picture full; web-share"/>}
                
            </div>
            <Row>
                <Col style={{marginTop: '95vh'}}>
                    <h1>{currentEvent?.title}</h1>
                    <p>{currentEvent?.longDesc}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>{currentEvent?.date && `Event Date and Time :${new Date(currentEvent?.date).toString()}`}</p>
                    {currentEvent?.contactPerson && <p><b>Contact Person: </b>{currentEvent?.contactPerson}</p>}
                </Col>
            </Row>
            <Row style={{marginBottom: '2rem'}}>
                <Col style={{justifyContent: 'end', display: 'flex'}}>
                    <Link to={currentEvent?.formLink} target="_blank" rel="noopener noreferrer">
                        <Button>Learn More</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
        
    
    {loading ? <Spinner/> : events && events.length > 0 &&
        <>
        <EventThumbnail events={events}/>
        <br />
        <br />
        {/* {lastFetchedEvents && (
            <p className="loadMore" onClick={onFetchMoreEvents}>Load More</p>
        )} */}
        </> 
        }
        <Footers/>
    </>
}



export default EventDetail