import { Container, Row, Col, Card, Button, Form, Modal, InputGroup } from 'react-bootstrap'
import {getAuth} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { collection, getDocs, query, doc, updateDoc, orderBy } from 'firebase/firestore'
import {getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject} from 'firebase/storage'
import {db} from '../../firebase.config'
import {v4 as uuidv4} from 'uuid'
import { Icon } from '@iconify/react';
import Spinner from '../../components/Spinner'
import { toast } from 'react-toastify'

const Cover = () => {
    const [covers, setCovers] = useState<any>(undefined)
    const [loading, setLoading] = useState(true)
    const [selectedCover, setSelectedCover] = useState<any>(undefined)
    const [imgLoc, setImgLoc] = useState('')
    const [showEdit, setshowEdit] = useState(false)

    const auth = getAuth()
    
    useEffect( () => {
        const fetchCovers = async () => {
            try {
                // get covers reference
                const coversRef = collection(db, 'covers')
                
                //create query
                const q = query(coversRef, orderBy("location", "desc"))

                //execute query
                const querySnap = await getDocs(q)

                let cover:any = []

                querySnap.forEach( (doc) => {
                    return cover.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setCovers(cover)
                setLoading(false)
            

            }catch(err){
                console.log(err)
            }
        }
        fetchCovers()
    },[showEdit])


    const handleCloseEdit = () => {
        setshowEdit(false)
    }
    const handleshowEdit = (obj:any) => {
        setImgLoc(obj.data.storageRef)
        setSelectedCover(obj)
        setshowEdit(true)
        console.log(imgLoc)

        // let s = imgLoc[0].split('%2F')
        // console.log(s)
    }



    const onSubmit = async (e:any) => {
        e.preventDefault()
    
        setLoading(true)
        let str
        // Store image in firebase
        const storeImage = async (image:any) => {
            return new Promise((resolve, reject) => {
            const storage = getStorage()
            const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
    
            const storageRef = ref(storage, 'images/' + fileName)
    
            const uploadTask = uploadBytesResumable(storageRef, image, image.type)
            
    
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log('Upload is ' + progress + '% done')
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused')
                    break
                  case 'running':
                    console.log('Upload is running')
                    break
                  default:
                    break
                }
              },
              (error) => {
                reject(error)
              },
              () => {
                
                // store image firestore location
                str = uploadTask.snapshot.ref.toString().split('/')
                
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL)
                })
              }
            )
          })
        }

        let imageUrls:any

        if (selectedCover.images){
            imageUrls = await Promise.all(
                [...selectedCover.images].map((image) => storeImage(image))
              ).catch(() => {
                setLoading(false)
                toast.error('Images not uploaded')
                return
              })
        }


        const selectedCoverCopy = {
            ...selectedCover,
        }
        
        const getUsername = auth.currentUser?.email?.split('@')

        selectedCoverCopy.data.imgUrl = !imageUrls ? selectedCoverCopy.data.img : imageUrls
        selectedCoverCopy.data.storageRef = str[str?.length - 1]
        selectedCoverCopy.data.modifiedBy = getUsername![0]
        delete selectedCoverCopy.id
        delete selectedCoverCopy.images

        // update listing
        const docRef = doc(db, 'covers', selectedCover.id)
        await updateDoc(docRef, selectedCover.data)

        
        const storage = getStorage();

        // Create a reference to the file to delete
        const desertRef = ref(storage, `images/${imgLoc}`);

        // Delete the file
        await deleteObject(desertRef).catch((err) => console.log(err))
        

        setLoading(false)
        setshowEdit(false)


        toast.success('Cover updated')
        // navigate(`/category/${formDataCopy.type}/${docRef.id}`)

      }

    
    const onMutate = (e:any) => {
    
        // Files
        if(e.target.files) {
            setSelectedCover((prevState:any) => ({
            ...prevState,
            images: e.target.files
          }))
        }


        // Text/Boolean/Numbers
        if(!e.target.files) {
            setSelectedCover((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data, [e.target.id]: e.target.value},
          } ))
        }
    }

    if(loading){
      return <Spinner/>
    }
  return (
<>
        <Container>
            <Row>
                <Col>
                <InputGroup style={{justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                    <Form.Control
                    placeholder="Search title"
                    aria-label="Search title"
                    disabled
                    />
                    <Button disabled variant="outline-secondary" style={{padding: '7px', margin: '0'}}><Icon icon="icon-park-outline:add" className="text-primary" style={{fontSize:"25px"}}/></Button>
                </InputGroup>
                </Col>
            </Row>
            
            <Row style={{marginTop: '10px'}}>
                {covers?.map( (cover: any, key: any) => {
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={cover.data.imgUrl} height={200} width={350} style={{objectFit: 'contain'}}/>
                        <Card.Body>
                            {cover.data.location && <Card.Title>{cover.data.location.toUpperCase()}</Card.Title>}
                            {cover.data.modifiedBy && <Card.Text><b>Modified by:</b> {cover.data.modifiedBy}</Card.Text>}
                        </Card.Body>
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                            <Button variant="primary" onClick={() => handleshowEdit(cover)} size="lg">Edit</Button>
                        </Card.Body>
                    </Card>
                </Col>
                })}                
            </Row>
        </Container>

        <Modal {...selectedCover} show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCover?.data.location.toUpperCase()}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
        <Modal.Body>
        <Card.Img variant="top" src={selectedCover?.data.imgUrl} />
            <Form.Group className="mb-3">
              <Form.Label>Cover Location: {selectedCover?.data.location.toUpperCase()}</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutate}
                required
              />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" type='submit' >
            Save Changes
          </Button>
        </Modal.Footer>
        </Form>
        {loading && <Spinner/>}
      </Modal>
    </>

    
  )
}

export default Cover