import React from 'react';
import Routing from './Routes';
import { app } from './firebase.config'
import './assets/scss/style.scss'

function App() {
  // eslint-disable-next-line
  const firebaseApp = app
  return (
    <React.Fragment>
      <Routing />
    </React.Fragment>
  );
}

export default App;
