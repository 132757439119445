import React from 'react'
import Navbars from './Navbar'

const Layout = ({children, isDark} : any) => {
  return (
    <React.Fragment>
      <Navbars isDark={isDark} />
        {children}
    </React.Fragment>
  )
}

export default Layout