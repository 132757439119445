import React from 'react'
import { Container} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import EventThumbnail from './EventThumbnail'
import {db} from '../firebase.config'
import Spinner from './Spinner'
import Footers from '../Layout/Footer'
import styled from 'styled-components'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div<{link?: string, link2?: string}>`

    background: url(${({link}) => link});
    position: absolute;
    left: 0;
    width: 100%;
    height: 90vh;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 700px){
        background: url(${({link2}) => link2});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

`

const Event = () => {
    const [events, setEvents] = useState<any>(undefined)
    const [featuredEvents, setFeaturedEvents] = useState<any>(undefined)
    const [loading, setLoading] = useState(true)
    // const [lastFetchedEvents, setLastFetchedEvents] = useState<any>(undefined)
    const [coverWeb, setCoverWeb] = useState<any>(undefined)
    const [coverMobile, setCoverMobile] = useState<any>(undefined)
    const nav = useNavigate()
    useEffect( () => {
        setLoading(true)
        const fetchEvents = async () => {
            try {
                // get events reference
                const eventsRef = collection(db, 'events')
                
                //create query
                const q = query(eventsRef, orderBy('date', 'desc'))

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedEvents(lastVisible)

                let obj:any = []

                querySnap.forEach( (doc) => {
                    return obj.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })
                
                // setFeaturedEvents(obj.filter(obj => {
                //     return obj?.data?.isFeatured.match(true)
                // }))

                setEvents(obj)
                
                let featured = obj.filter((obj:any) => {
                    console.log(obj.data)
                    return obj.data.isFeatured.toString().match('true')
                })

                setFeaturedEvents(featured)

                setLoading(false)

            }catch(err){
                console.log(err)
            }
        }
        fetchEvents()
    },[])

    useEffect( () => {
        setLoading(true)
        const fetchCovers = async () => {
            try {
                // get events reference
                const eventsRef = collection(db, 'covers')
                
                //create query
                const q = query(eventsRef, orderBy('location', 'desc'))

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedEvents(lastVisible)

                let cover:any = []

                querySnap.forEach( (doc) => {
                    return cover.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setCoverWeb(cover.filter(obj => {
                    return obj?.data?.location.match('events web')
                } ))

                // console.log(coverWeb[0]?.data?.imgUrl[0])


                setCoverMobile(cover.filter(obj => {
                    return obj?.data?.location.match('events mobile')
                } ))

                setLoading(false)

                // console.log(events)

            }catch(err){
                console.log(err)
            }
        }
        fetchCovers()
    },[])

    const handleClick = (id) =>{
        nav(id)
    }
//     const onFetchMoreEvents = async () => {
//       try{

//           // Get Reference
//           const eventsRef = collection(db, 'events')

//           //create query
//           const q = query(
//               eventsRef, 
//               orderBy('date', 'desc'), 
//               startAfter(lastFetchedEvents),
//               limit(10)
//               )
          
//           // execute query
//           const querySnap = await getDocs(q)
//           const lastVisible = querySnap.docs[querySnap.docs.length - 1]

//           const events:any[] = []

//           querySnap.forEach((doc) => {
//               return events.push({
//                   id:doc.id,
//                   data: doc.data(),
//               })
//           })

//           setEvents( (prevState:any) => [...prevState, ...events])
//           setLoading(false)

//       }catch(err){
//           console.log(err)
//       }
//   }
    console.log(featuredEvents)
  return (
   
    <React.Fragment>
     <Container fluid="sm">
        {/* <div className="event-hero-1-bg" style={style}>
        </div> */}
        {coverWeb && coverMobile && <Wrapper link={coverWeb[0]?.data?.imgUrl[0]} link2={coverMobile[0]?.data?.imgUrl[0]}/>}    
    </Container>

    <Container style={{paddingTop: '10vh'}}>
        <Swiper slidesPerView={1}
                pagination={{ clickable: true}}
                modules={[Pagination, Autoplay]}
                spaceBetween={40}
                centeredSlides={true}
                loop={true}
                // freeMode={true}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                className="client-swiper"
                >
                {featuredEvents?.map((obj:any, key:any)=>(
                    <SwiperSlide className="client-slider" key={key}>
                        <div style={{height: '80vh', width: '100%', background: `url(${obj?.data?.imgUrl[0]}) center center`, borderColor: 'unset', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}} 
                        onClick={(e) =>{e.preventDefault(); handleClick(obj?.id)} }/>
                    </SwiperSlide>
                ))}
        </Swiper>  
    </Container>

    {loading ? <Spinner/> : events && events.length && 
        <>
        <div style={{marginTop: featuredEvents?.length > 0 ? '0' : '85vh'}}>
            <EventThumbnail events={events}/>
        </div>
        <br />
        <br />
        {/* {lastFetchedEvents && (
            // <p className="loadMore" onClick={onFetchMoreEvents}>Load More</p>
        )} */}
        </>
        }

    <Footers/>
    </React.Fragment>
  )
}

export default Event