import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

import { Icon } from '@iconify/react';

const About = () => {
  return (
    <React.Fragment>
    <section className="section"  id="about">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="text-center mb-5 mt-5">
              <h3 className="mb-3">About Us</h3>
              <div className="text-center title mb-5">
              <div className="title-icon position-relative">
                <div className="position-relative">
                  <Icon icon="uim-arrow-circle-down" className="text-primary" style={{fontSize:"25px"}}></Icon>
                </div>
              </div>
            </div>
              <p className="text-muted">
              Mixed Up Entertainment Production was launched in 2018, a full-service enterteinment company that creates opportunities and develops sustainable career to all our artists in constant search of talents to nurture through training and development to representation in the fields of Media, Entertainment, Events, Live streaming platform and more.
              </p>
              
            </div>
          </Col>
        </Row>
      

        <Row>
          <Col lg={4} md={4}>
            <div className="text-center p-4">
              <div className="icons-xl mb-3">
                <Icon icon="radix-icons:rocket" className='text-primary'></Icon>
              </div>

              <h5>Our Mission</h5>
              <p className="text-muted">To enhance people's inspiration, creativity and self-expression.
              To provide quality performers that transforms innovative concepts into targeted oppotunities for our clients, while building a reputation for ourselves as a dependable talent provider.</p>
            </div>
          </Col>

          <Col lg={4} md={4}>
            <div className="text-center p-4">
              <div className="icons-xl mb-3">
                <Icon icon="iconoir:light-bulb-on" className='text-primary'></Icon>
              </div>

              <h5>Our Vision</h5>
              <p className="text-muted">Our vision is to become the first choice of clients
              seeking sustainable talent provider services in the Philippines</p>
            </div>
          </Col>

          <Col lg={4} md={4}>
            <div className="text-center p-4">
              <div className="icons-xl mb-3">
                <Icon icon="lucide:helping-hand" className="text-primary"></Icon>
              </div>

              <h5>Our Values</h5>
              <p className="text-muted">Delivering the highest quality of talents and performers.
Our key values include responsibility, professionalism, vigilance and safety.</p>
            </div>
          </Col>
        </Row>
        
      </Container>
      
    </section>


  </React.Fragment>

   
  )
}

export default About