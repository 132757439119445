import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import logoLight from '../../assets/images/logo-light.png'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { toast } from 'react-toastify'
// import { app } from '../../firebase.config'

const Authlogin = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  // const firebaseApp = app

  const {email, password} = formData

  const navigate = useNavigate()

  const onChange = (e:any) => {
    setFormData( (prev) => ({
      ...prev,
      [e.target.id]: e.target.value
    }))
  }

  const onSubmit =async (e:any) => {
    e.preventDefault()

    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(auth, email, password)

      if(userCredential.user){
        toast.success('Logged in')
        navigate('/dashboard')
      }
    } catch (error) {
      toast.error('Bad User Credentials')
    }

    
  }
  return (
    <div className="authentication-page bg-primary auth-1 py-5">
      {/* bg-overlay-img */}
      <div className="bg-overlay overflow-hidden bg-transparent">
        <div className="auth-1-bg"></div>
      </div>

      <Container>
        <Row className="justify-content-center mt-sm-5">
          <Col md={8} lg={6} xl={5}>
            <div>
              <div className="text-center mb-5">
                <Link to="/" className="mb-3 d-block auth-logo">
                  <img src={logoLight} alt="" height="100" className="logo" />
                </Link>
              </div>

              <Card>
                <Card.Body className="p-4">
                  <div className="text-center mt-2">
                    <h5>Welcome Back !</h5>
                    <p>Sign in to continue to Dashboard.</p>
                  </div>

                  <div className="p-2 mt-4">
                    <Form action="index-1" onSubmit={onSubmit}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={onChange}/>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <InputGroup >
                            <Form.Control type={showPassword ? 'text' : 'password'} placeholder="Enter password" value={password} onChange={onChange}>
                            </Form.Control>
                            <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                              {!showPassword ? 
                                <Icon icon="material-symbols:visibility" className="text-primary" style={{fontSize:"25px"}}/> : 
                                <Icon icon="material-symbols:visibility-off" className="text-primary" style={{fontSize:"25px"}} /> }
                            </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>

                      {/* <Form.Group className='d-flex'>
                      
                        <Form.Check
                          type="checkbox"
                          className="form-check-input"
                          id="auth-remember-check"
                          label="Remember me"
                        > </Form.Check>
                        <Form.Label className='ms-2 mb-1'>Remember me</Form.Label>
                      </Form.Group> */}

                      <div className="mt-5 d-grid">
                        <Button variant="info" type="submit" 
                            className="g-recaptcha" 
                            data-sitekey="6LeJ_AspAAAAAHyPvS20JvRNHT-lPOx4Y8Vxn4fl" 
                            data-callback='onSubmit' 
                            data-action='submit'>
                          Log In
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/recover-password" className="text-body">
                          <i className="mdi mdi-lock me-1"></i> Forgot your password?
                        </Link>
                      </div>
                    </Form>
                    {/* end-form */}
                  </div>
                </Card.Body>
              </Card>

              {/* <div className="mt-4 text-center text-white-50">
                <p>
                  Don't have an account ?{' '}
                  <Link to="/auth-register" className="font-weight-semibold text-white">
                    Signup
                  </Link>{' '}
                </p>
              </div> */}
            </div>
          </Col>
        </Row>
        {/* end row */}
      </Container>
      {/* end container */}
    </div>
  )
}

export default Authlogin
