import About from '../../components/About';
import Contact from '../../components/Contact'
import Footers from '../../Layout/Footer';
import Services from '../../components/Services';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components'
import { useState, useEffect } from 'react';
import { collection, getDocs, query } from 'firebase/firestore'
import {db} from '../../firebase.config'
import Spinner from '../../components/Spinner';

const Wrapper = styled.div<{link?: string, link2?: string}>`

    background: url('${({link}) => link}');
    position: absolute;
    left: 0;
    width: 100%;
    height: 95vh;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -95vh;

    @media (max-width: 700px){
        background: url(${({link2}) => link2});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

`

const Section = () => {
  const [coverWeb, setCoverWeb] = useState<any>(undefined)
  const [coverMobile, setCoverMobile] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect( () => {
    setLoading(true)
    const fetchCovers = async () => {
        try {
            // get events reference
            const eventsRef = collection(db, 'covers')
            
            //create query
            const q = query(eventsRef)

            //execute query
            const querySnap = await getDocs(q)

            // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

            // setLastFetchedEvents(lastVisible)

            let cover:any = []

            querySnap.forEach( (doc) => {
                return cover.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setCoverWeb(cover.filter(obj => {
                return obj?.data?.location.match('home web')
            } ))

            // console.log(coverWeb[0]?.data?.imgUrl[0])


            setCoverMobile(cover.filter(obj => {
                return obj?.data?.location.match('home mobile')
            } ))

            setLoading(false)

            // console.log(events)

        }catch(err){
            console.log(err)
        }
    }
    fetchCovers()
},[])

  if(loading){
    return <Spinner/>
  }
  return <>
  <section className="hero-1 bg-primary">
  
  <div className=" overflow-hidden bg-transparent">

      {coverWeb && coverMobile && <Wrapper link={`${coverWeb[0]?.data?.imgUrl[0]}`} link2={`${coverMobile[0]?.data?.imgUrl[0]}`}/>}
      
      {/* <div className="hero-1-bg" id="home">
            <img src={logo} alt="Mixedup Entertainment Productions" className='mobile-logo'/>
            <h5 style={{color:'white', margin: '0 3em', marginTop:'-10em'}}>TAGLINE SAMPLE Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab, perspiciatis?</h5>
      </div> */}


  </div>

  <Container>
    <Row className="justify-content-center">
      <Col lg={9}>
        <div className="hero-wrapper text-white-50 text-center">

        </div>
      </Col>
    </Row>
    
  </Container>
  
  <About/>
  <Services/>
  <Contact/>
  <Footers/>
</section>

  </>

  
}

export default Section