import { Container, Row, Col, Card, Button, Form, Modal, InputGroup } from 'react-bootstrap'
import {getAuth} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { collection, getDocs, query, limit, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import {getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject} from 'firebase/storage'
import {db} from '../../firebase.config'
import {v4 as uuidv4} from 'uuid'
import { Icon } from '@iconify/react';
import Spinner from '../../components/Spinner'
import { toast } from 'react-toastify'

const Members = () => {
    const [members, setMembers] = useState<any>(undefined)
    const [agencies, setAgencies] = useState<any>(undefined)
    const [loading, setLoading] = useState(true)
    const [imgLoc, setImgLoc] = useState('')
    // const [lastFetchedAgencies, setLastFetchedAgencies] = useState<any>(undefined)
    const [selectedMember, setSelectedMember] = useState<any>(undefined)
    const [createMember, setCreateMember] = useState<any>(undefined)
    const [deleteMember, setDeleteMember] = useState<any>(undefined)
    const [showEdit, setshowEdit] = useState(false)
    const [showCreate, setshowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [text, setText] = useState('')
    const [clearFilter, setClearFilter] = useState(true)
    const [filteredMembers, setFilteredMembers] = useState<any>(undefined)

    const auth = getAuth()

    useEffect( () => {
        const fetchMembers = async () => {
            try {
                // get members reference
                const membersRef = collection(db, 'member')
                
                //create query
                const q = query(membersRef, limit(10))

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedAgencies(lastVisible)

                let member:any = []

                querySnap.forEach( (doc) => {
                    return member.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setMembers(member)
                setLoading(false)

                // console.log(events)

            }catch(err){
                console.log(err)
            }
        }
        fetchMembers()
    },[showEdit, showCreate, showDelete])

    useEffect( () => {
      const fetchAgencies = async () => {
          try {
              // get members reference
              const agenciesRef = collection(db, 'agency')
              
              //create query
              const q = query(agenciesRef)

              //execute query
              const querySnap = await getDocs(q)

              // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

              // setLastFetchedAgencies(lastVisible)

              let agencies:any = []

              querySnap.forEach( (doc) => {
                  return agencies.push({
                      id: doc.id,
                      agencyName: doc.data().title
                  })
              })

              setAgencies(agencies)
              setLoading(false)

          }catch(err){
              console.log(err)
          }
      }

      fetchAgencies()
  },[showEdit, showCreate, showDelete])

    const onFetchAgencies = (id:any) =>{
      for(let i = 0; i < agencies?.length; i++) {
        if(agencies[i]?.id === id){
              return agencies[i]?.agencyName
            }
      }
    }

    const handleCloseEdit = () => {
        setshowEdit(false)
    }
    const handleshowEdit = (event:any) => {
        setSelectedMember(event)
        setshowEdit(true)
    }


    const handleCloseCreate = () => {
        setshowCreate(false)
    }
    const handleshowCreate = () => {
        // setSelectedEvent(event)
        setCreateMember({
            'id': '', 
            'data': {
                'name': '',
                'followerCount': '',
                'ign': '',
                // 'agency': '',
                'agency': agencies[0]?.id,
        }})
        // console.log(selectedEvent)
        setshowCreate(true)
    }


    const onSubmit = async (e:any) => {
        e.preventDefault()
    
        setLoading(true)
        let str
        // Store image in firebase
        const storeImage = async (image:any) => {
            return new Promise((resolve, reject) => {
            const storage = getStorage()
            const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
            
            const storageRef = ref(storage, 'images/' + fileName)
    
            const uploadTask = uploadBytesResumable(storageRef, image, image.type)
    
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log('Upload is ' + progress + '% done')
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused')
                    break
                  case 'running':
                    console.log('Upload is running')
                    break
                  default:
                    break
                }
              },
              (error) => {
                reject(error)
              },
              () => {
                // store image firestore location
                str = uploadTask.snapshot.ref.toString().split('/')

                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL)
                })
              }
            )
          })
        }
        let imageUrls:any

        if (selectedMember.images){
            imageUrls = await Promise.all(
                [...selectedMember.images].map((image) => storeImage(image))
              ).catch(() => {
                setLoading(false)
                toast.error('Images not uploaded')
                return
              })
        }


        const selectedMemberCopy = {
            ...selectedMember,
        }
        
        const getUsername = auth.currentUser?.email?.split('@')

        selectedMemberCopy.data.img = !imageUrls ? selectedMemberCopy.data.img : imageUrls
        selectedMemberCopy.data.modifiedBy = getUsername![0]
        selectedMemberCopy.data.storageRef = !imageUrls ? (selectedMember.data.storageRef ? selectedMember.data.storageRef : '') : str[str.length - 1]
        delete selectedMemberCopy.id
        delete selectedMemberCopy.images

    
        // update listing
        const docRef = doc(db, 'member', selectedMember.id)
        await updateDoc(docRef,selectedMemberCopy.data)

        
        const storage = getStorage();

        // Create a reference to the file to delete
        const desertRef = ref(storage, `images/${imgLoc}`);
  
        // Delete the file
        await deleteObject(desertRef).catch((err) => console.log(err))


        setLoading(false)
        setshowEdit(false)

        toast.success('Member updated')
        // navigate(`/category/${formDataCopy.type}/${docRef.id}`)

      }


    const onSubmitCreate = async (e:any) => {
      e.preventDefault()
      setLoading(true)

      let str
  
      // Store image in firebase
      const storeImage = async (image:any) => {
          return new Promise((resolve, reject) => {
          const storage = getStorage()
          const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
  
          const storageRef = ref(storage, 'images/' + fileName)
  
          const uploadTask = uploadBytesResumable(storageRef, image, image.type)
  
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
                  break
                default:
                  break
              }
            },
            (error) => {
              reject(error)
            },
            () => {
              // store image firestore location
              str = uploadTask.snapshot.ref.toString().split('/')

              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL)
              })
            }
          )
        })
      }
      let imageUrls:any

      if (createMember.images){
          imageUrls = await Promise.all(
              [...createMember.images].map((image) => storeImage(image))
            ).catch(() => {
              setLoading(false)
              toast.error('Images not uploaded')
              return
            })
      }

      const createMemberCopy = {
          ...createMember,
      }
      
      const getUsername = auth.currentUser?.email?.split('@')
      


      createMemberCopy.data.img = !imageUrls ? createMemberCopy.data.imgCover : imageUrls
      createMemberCopy.data.modifiedBy = getUsername![0]
      createMemberCopy.data.createdBy = getUsername![0]
      createMemberCopy.data.storageRef = !imageUrls ? (createMemberCopy.data.storageRef ? createMemberCopy.data.storageRef : '') : str[str.length - 1]
      delete createMemberCopy.id 
      delete createMemberCopy.images

      const docRef = await addDoc(collection(db, 'member'), createMemberCopy.data)
      setLoading(false)
      setshowCreate(false)

      toast.success('Member added')
      // navigate(`/category/${formDataCopy.type}/${docRef.id}`)

    }
    
    const onMutate = (e:any) => {
    
        // Files
        if(e.target.files) {
            setSelectedMember((prevState:any) => ({
            ...prevState,
            images: e.target.files
          }))
        }


        // Text/Boolean/Numbers
        if(!e.target.files) {
            setSelectedMember((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data, [e.target.id]: e.target.value},
          } ))
        }

        // console.log(selectedMember)
        // console.log(e.target)
    }

    const onMutateCreate = (e:any) => {
    
        // Files
        if(e.target.files) {
            setCreateMember((prevState:any) => ({
            ...prevState,
            images: e.target.files
          }))
        }

        // Text/Boolean/Numbers
        if(!e.target.files) {
            setCreateMember((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data, [e.target.id]: e.target.value},
          } ))
        }

    }

    const handleCloseDelete = () => {
      setShowDelete(false)
    }

    const handleShowDelete = (member:any) => {
      setImgLoc(member?.data?.storageRef)
      setDeleteMember(member)
      setShowDelete(true)
    }

    const onDelete = async (e:any) =>{
      e.preventDefault()
      const storage = getStorage();

        // Create a reference to the file to delete
      const desertRef = ref(storage, `images/${imgLoc}`);
      // Delete the file
      await deleteObject(desertRef).catch((err) => console.log(err))

      await deleteDoc(doc(db, "member", deleteMember.id));
      setShowDelete(false)
    }

    const handleFilterSearch = (e) => {
      e.preventDefault()

      setText(e.target.value)
      setClearFilter(false)
      const filtered = members.filter(obj => {
        const agency = onFetchAgencies(obj.data.agency)
        return obj.data.name.toLowerCase().match(e.target.value) || agency.toLowerCase().match(e.target.value);
      });

      setFilteredMembers(filtered)
    }

    if(loading){
      return <Spinner/>
    }
  return (
    <>
        <Container>
            <Row>
                <Col>
                <InputGroup style={{justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                    <Form.Control
                    placeholder="Search title"
                    aria-label="Search title"
                    value={text}
                    onChange={handleFilterSearch}
                    />
                    <Button onClick={() => handleshowCreate()}  variant="outline-secondary" style={{padding: '7px', margin: '0'}}><Icon icon="icon-park-outline:add" className="text-primary" style={{fontSize:"25px"}}/></Button>
                </InputGroup>
                </Col>
            </Row>
            
            <Row style={{marginTop: '10px'}}>
                {clearFilter && members?.map( (member: any, key: any) => {
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={member.data.img} height={200} width={350} style={{objectFit: 'contain'}}/>
                        <Card.Body>
                            {member.data.name && <Card.Title>{member.data.name}</Card.Title>}
                            {member.data.followerCount && <Card.Text style={{margin: 0}}><b>Followers :</b> {member.data.followerCount}</Card.Text>}
                            {member.data.ign && <Card.Text style={{margin: 0}}><b>Tag: </b> {member.data.ign}</Card.Text>}
                            {member.data.agency && <Card.Text style={{margin: 0}} dangerouslySetInnerHTML={{__html: `<b>Agency: </b> ${onFetchAgencies(member.data.agency)}`}}></Card.Text>}
                            {member.data.createdBy && <Card.Text style={{margin: 0}}><b>Created by:</b> {member.data.createdBy}</Card.Text>}
                            {member.data.modifiedBy && <Card.Text><b>Modified by:</b> {member.data.modifiedBy}</Card.Text>}
                        </Card.Body>
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                            <Button variant="primary" onClick={() => handleshowEdit(member)} >Edit</Button>
                            <Button variant="danger" onClick={() => handleShowDelete(member)} >Delete</Button>
                        </Card.Body>
                    </Card>
                </Col>
                })}

                {!clearFilter && filteredMembers?.map( (member: any, key: any) => {
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={member.data.img} height={200} width={350} style={{objectFit: 'contain'}}/>
                        <Card.Body>
                            {member.data.name && <Card.Title>{member.data.name}</Card.Title>}
                            {member.data.followerCount && <Card.Text style={{margin: 0}}><b>Followers :</b> {member.data.followerCount}</Card.Text>}
                            {member.data.ign && <Card.Text style={{margin: 0}}><b>Tag: </b> {member.data.ign}</Card.Text>}
                            {member.data.agency && <Card.Text style={{margin: 0}} dangerouslySetInnerHTML={{__html: `<b>Agency: </b> ${onFetchAgencies(member.data.agency)}`}}></Card.Text>}
                            {member.data.createdBy && <Card.Text style={{margin: 0}}><b>Created by:</b> {member.data.createdBy}</Card.Text>}
                            {member.data.modifiedBy && <Card.Text><b>Modified by:</b> {member.data.modifiedBy}</Card.Text>}
                        </Card.Body>
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                            <Button variant="primary" onClick={() => handleshowEdit(member)} >Edit</Button>
                            <Button variant="danger" onClick={() => handleShowDelete(member)} >Delete</Button>
                        </Card.Body>
                    </Card>
                </Col>
                })}
                
            </Row>
        </Container>

        <Modal {...selectedMember} show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
        <Modal.Body>
        <Card.Img variant="top" src={selectedMember?.data.img} />
            <Form.Group className="mb-3">
              <Form.Label>Event Cover Image</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutate}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedMember?.data.name}
                onChange={onMutate}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="followerCount">
                <Form.Label>Follower Count</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedMember?.data?.followerCount}
                    onChange={onMutate}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="ign">
                <Form.Label>@Tag</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedMember?.data.ign}
                    onChange={onMutate}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="agency">
                <Form.Label>Agency</Form.Label>
                <Form.Select aria-label="Agency" onChange={onMutate} value={selectedMember?.data.agency} required>
                  {agencies?.map((agency, key)=> (
                    <option value={agency?.id} key={key}>{agency?.agencyName}</option>
                  ))}
                </Form.Select>
            </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" type='submit' >
            Save Changes
          </Button>
        </Modal.Footer>
        </Form>
        {loading && <Spinner/>}
      </Modal>


      <Modal {...createMember} show={showCreate} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Add Agency</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmitCreate}>
        <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Member Photo</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutateCreate}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={createMember?.data.name}
                onChange={onMutateCreate}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="followerCount">
                <Form.Label>Follower Count</Form.Label>
                <Form.Control
                    type="text"
                    value={createMember?.data?.followerCount}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="ign">
                <Form.Label>@Tag</Form.Label>
                <Form.Control
                    type="text"
                    value={createMember?.data.ign}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="agency">
                <Form.Label>Agency</Form.Label>
                <Form.Select aria-label="Select Agency" onChange={onMutateCreate} value={createMember?.data.agency} required>
                  {agencies?.map((agency, key)=> (
                    <option value={agency?.id} key={key}>{agency?.agencyName}</option>
                  ))}
                </Form.Select>
            </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreate}>
            Close
          </Button>
          <Button variant="primary" type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
        </Form>
        {loading && <Spinner/>}
      </Modal>




      <Modal {...deleteMember} show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Member</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onDelete}>
        <Modal.Body>
            <p>Are you sure you want to delete <b>{deleteMember?.data.name}</b></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" type='submit'>
            Delete
          </Button>
        </Modal.Footer>
        </Form>
        {loading && <Spinner/>}
      </Modal>
    </>
  )
}

export default Members