
type BackgroundProps = {
    children: React.ReactNode
}

const Background = (props: BackgroundProps) => {
 return <>
    <div className="dashboard-background">
    {props.children}
    </div>
    </>
}

export default Background