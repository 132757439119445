import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import img1 from '../assets/images/img1.png'
import img2 from '../assets/images/img2.png'
import img3 from '../assets/images/img3.png'
import img4 from '../assets/images/img4.png'
import { Icon } from '@iconify/react';

const Features = () => {
  return (
   <React.Fragment>
    <section  id="features">
  <Container>
    <Row className="justify-content-center">
      <Col lg={8}>
        <div className="text-center title mb-5">
          <h3 className="mb-3">Join Us</h3>
          <div className="title-icon position-relative">
            <div className="position-relative">
              <Icon icon="uim-arrow-circle-down" className='text-primary' style={{fontSize: "25px"}}></Icon>
            </div>
          </div>
        </div>
      </Col>
    </Row>
   

    <Row className="align-items-center">
      <Col lg={5} order={{ sm: 'last' }}>
        <div className="mt-4 mt-lg-0">
          <h4>Partnership</h4>
          <p className="text-muted mt-3">Mixed Up Entertainment Production is proud to announce that we have formed a partnership with TikTok Live Streaming. This partnership solidifies our position as an official partner of TikTok, a leading platform for digital content creation and social media sharing.</p>
          <div className="mt-4">
            <Link to="/" className="text-primary">Learn more..</Link>
          </div>
        </div>
      </Col>

      <Col lg={6} ms={{ lg: 'auto' }} sm={8} order={{ sm: 'first' }}>
        <div>
          <Image src={img1} alt="" fluid className="mx-auto d-block" />
        </div>
      </Col>
    </Row>
    {/* end row */}

    <hr className="my-5" />

    <Row className="align-items-center">
      <Col lg={6} sm={8}>
        <div className="features-img mt-4">
          <Image src={img2} alt="" fluid className="mx-auto d-block" />
        </div>
      </Col>

      <Col lg={5} ms={{ lg: 'auto' }}>
        <div className="mt-5 mt-lg-4">
          <h4>Collaboration</h4>
          <p className="text-muted mb-2 mt-3">As part of this collaboration, Mixed Up Entertainment Production will have exclusive access to TikTok's live-streaming features, allowing us to maximize our reach and engage with our audience in real time.</p>
          <p className="text-muted">This partnership provides us with a unique opportunity to showcase our talent and entertain and inspire millions of viewers on TikTok.</p>
          <div className="mt-4">
            <Link to="/" className="text-primary">Learn more..</Link>
          </div>
        </div>
      </Col>
    </Row>

    <hr className="my-5" />

    <Row className="align-items-center">
      <Col lg={5} order={{ sm: 'last' }}>
        <div className="mt-4 mt-lg-0">
          <h4>Expertise</h4>
          <p className="text-muted mt-3">By combining our expertise in entertainment production with TikTok's innovative platform, we are confident that we can create compelling and engaging content for our viewers.</p>
          <p className="text-muted mt-3">Whether it be live performances, interactive Q&A sessions, or behind-the-scenes glimpses into our productions, we are excited to bring a new level of entertainment to the TikTok community.</p>
          <div className="mt-4">
            <Link to="/" className="text-primary">Learn more..</Link>
          </div>
        </div>
      </Col>

      <Col lg={6} ms={{ lg: 'auto' }} sm={8} order={{ sm: 'first' }}>
        <div>
          <Image src={img3} alt="" fluid className="mx-auto d-block" />
        </div>
      </Col>
    </Row>

    <hr className="my-5" />

    <Row className="align-items-center">
      <Col lg={6} sm={8}>
        <div className="features-img mt-4">
          <Image src={img4} alt="" fluid className="mx-auto d-block" />
        </div>
      </Col>

      <Col lg={5} ms={{ lg: 'auto' }}>
        <div className="mt-5 mt-lg-4">
          <h4>Experience</h4>
          <p className="text-muted mb-2 mt-3">We are thrilled to be recognized as an official partner of TikTok and look forward to further expanding our reach and impact through this collaboration. Stay tuned for exciting live-streaming events and exclusive content that will be shared exclusively on TikTok. </p>
          <p className="text-muted">Together, we will create a unique and memorable entertainment experience for our audience.</p>
          <div className="mt-4">
            <Link to="/" className="text-primary">Learn more..</Link>
          </div>
        </div>
      </Col>
    </Row>


    <hr className="my-5" />
    
  </Container>
  
</section>

   </React.Fragment>

   
  )
}

export default Features