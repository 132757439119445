import React from 'react'

const Nonauthlayout = ({children}:any) => {
  return (
   <React.Fragment>
    {children}
   </React.Fragment>
  )
}

export default Nonauthlayout