import { Col, Container, Row, Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';

const EventThumbnail = (props:any) => {
    const {events, mt} = props
 return <>
 <section className="section event-section">
    <Container>
        <Row className="justify-content-center" style={{marginTop: `${mt}`}}>
            <Col lg={8}>
                <div className="text-center title mb-5">
                <h3 className="mb-3">Our Events</h3>
                <div className="title-icon position-relative">
                    <div className="position-relative">
                    <Icon icon="uim-arrow-circle-down" className='text-primary' style={{fontSize: "25px"}}></Icon>
                    </div>
                </div>
                </div>
            </Col>
        </Row>
        <Row className="justify-content-center">
            {events.map((event:any, key:any) => (
            <Col xs sm={12} md={6} key={key} className="event-img-container justify-content-center">
                    <Card style={{ width: '25rem' }}>
                    <Card.Img variant="top" src={event.data.imgUrl} height={200} width={350} style={{objectFit: 'cover'}}/>
                    <Card.Body>
                        <Card.Title>{event.data.title}</Card.Title>
                        <Card.Text>{event.data.shortDesc}
                        </Card.Text>
                        <Link to={`/feature/${event.id}`}>
                        <Button variant="primary">View</Button>
                        </Link>
                    </Card.Body>
                    </Card>
            </Col>
            ))}
        </Row>
    </Container>
 </section>
 </>
}

export default EventThumbnail