/** Add Route Component */
import Layout1 from "../pages/Layout1/Layout1";
import Authlogin from "../pages/authlogin/Authlogin";
import Authrecoverpw from "../pages/authrecoverpw/Authrecoverpw";
import Dashboard from "../pages/dashboard/Dashboard";
import Events from "../pages/events/Events";
import EventDetail from "../components/EventDetail";
import Agency from "../components/Agency";

interface RouteProps {
  path: string;
  component?: any;
  exact?: boolean;
  isDark : boolean;
}

const routes: Array<RouteProps> = [
  { path: "/", component: <Layout1/>, isDark : false },
  { path: "/feature", component: <Events/>, isDark : false },
  { path: "/feature/:id", component: <EventDetail/>, isDark : false },
  { path: "/agency", component: <Agency/>, isDark : false },
];

const publicroute =[
  {path: "/login", component: <Authlogin />},
  {path: "/recover-password", component: <Authrecoverpw />},
  {path: "/dashboard", component: <Dashboard />},
]
export {publicroute,routes} 
