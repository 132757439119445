import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import logoLight from '../assets/images/logo-light.png'

const Footers = () => {
  return (
   <React.Fragment>
    <footer className="footer bg-dark text-white-50">
  <Container>
    <Row>
      <Col lg={4}>
        <Link to="/" className="d-block mb-3">
          <img src={logoLight} alt="" height="50" />
        </Link>
        <p>Mixed Up Entertainment Production was launched in 2018, a full-service enterteinment company that creates opportunities and develops sustainable career to all our artists in constant search of talents to nurture through training and development to representation in the fields of Media, Entertainment, Events, Live streaming platform and more.</p>
      </Col>

      <Col lg={2} sm={6}>
        <div className="mt-4 mt-lg-0">
          <h5 className="mb-4 font-18 text-white">Links</h5>
          <ul className="list-unstyled footer-list-menu">
            <li><Link to="/login">Admin</Link></li>
            <li><Link to="/#about">About us</Link></li>
            <li><Link to="/#contact">Contact us</Link></li>
          </ul>
        </div>
      </Col>

      <Col lg={3} sm={6}>
        <div className="mt-4 mt-lg-0">
          <h5 className="mb-4 font-18 text-white">Resources</h5>
          <ul className="list-unstyled footer-list-menu">
            <li><Link to="#">Help Center</Link></li>
            <li><Link to="#">Privacy Policy</Link></li>
            <li><Link to="#">Terms & Conditions</Link></li>
          </ul>
        </div>
      </Col>
      <Col lg={3} sm={6}>
        <div className="mt-4 mt-lg-0">
          <h5 className="mb-4 font-18 text-white">Social</h5>
          <ul className="list-inline social-icons-list">
            <li className="list-inline-item">
              <Link to="#"><i className="mdi mdi-facebook"></i></Link>
            </li>
            <li className="list-inline-item">
              <Link to="#"><i className="mdi mdi-twitter"></i></Link>
            </li>
            <li className="list-inline-item">
              <Link to="#"><i className="mdi mdi-linkedin"></i></Link>
            </li>
            <li className="list-inline-item">
              <Link to="#"><i className="mdi mdi-google-plus"></i></Link>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
   
  </Container>
 
</footer>


    <section className="footer-alt py-3">
  <Container>
    <Row>
      <Col lg={12}>
        <div className="text-center text-white-50">
          <p className="mb-0">
            {new Date().getFullYear()} © Mixed Up Entertainment Prod
          </p>
        </div>
      </Col>
    </Row>
   
  </Container>
  
</section>
   </React.Fragment>
  )
}

export default Footers