import { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import logoLight from '../../assets/images/logo-light.png'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const Authrecoverpw = () => {
  const [email, setEmail] = useState('')

  const onChange = (e) => setEmail(e.target.value)

  const onSubmit= async (e) => {
    e.preventDefault()
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      toast.success('Email was sent.')
    } catch (error) {
      toast.error('Could not send reset email.')
    }
  }

  return (
   <>
        <div className="authentication-page bg-primary auth-1 py-5">
      {/* bg-overlay-img */}
      <div className="bg-overlay overflow-hidden bg-transparent">
        <div className="auth-1-bg"></div>
      </div>

      <Container>
        <Row className="justify-content-center mt-sm-5">
          <Col md={8} lg={6} xl={5}>
            <div>
              <div className="text-center mb-5">
                <Link to="/" className="mb-3 d-block auth-logo">
                  <img src={logoLight} alt="" height="100" className="logo" />
                </Link>
              </div>

              <Card>
                <Card.Body className=" p-4">
                  <div className="text-center mt-2">
                    <h5>Reset Password</h5>
                  </div>

                  <div className="p-2 mt-4">
                    <Alert variant="warning" className="text-center mb-4">
                      Enter your email address and we'll send you an email with instructions to reset your password
                    </Alert>
                    <Form onSubmit={onSubmit}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={onChange}/>
                      </Form.Group>

                      <div className="mt-3 d-grid">
                        <Button variant="info" 
                            type="submit" 
                            className="g-recaptcha" 
                            data-sitekey="6LeJ_AspAAAAAHyPvS20JvRNHT-lPOx4Y8Vxn4fl" 
                            data-callback='onSubmit' >
                          Reset
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
              {/* end card */}

              <div className="mt-4 text-center text-white-50">
                <p>
                  Remember It ?{' '}
                  <Link to="/login" className="font-weight-semibold text-white">
                    Signin
                  </Link>{' '}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      
      </Container>
     
    </div>
 
   </>
  )
}

export default Authrecoverpw