import { Container, Row, Col, Card, Button, Form, Modal, InputGroup } from 'react-bootstrap'
import {getAuth} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { collection, getDocs, query, orderBy, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import {getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject} from 'firebase/storage'
import {db} from '../../firebase.config'
import {v4 as uuidv4} from 'uuid'
import { Icon } from '@iconify/react';
import Spinner from '../../components/Spinner'
import { toast } from 'react-toastify'

const Events = () => {
    const [events, setEvents] = useState<any>(undefined)
    const [filteredEvents, setFilteredEvents] = useState<any>(undefined)
    const [clearFilter, setClearFilter] = useState(true)
    const [loading, setLoading] = useState(true)
    const [text, setText] = useState('')
    const [imgLoc, setImgLoc] = useState('')
    // const [lastFetchedEvents, setLastFetchedEvents] = useState<any>(undefined)
    const [selectedEvent, setSelectedEvent] = useState<any>(undefined)
    const [createEvent, setCreateEvent] = useState<any>(undefined)
    const [showEdit, setshowEdit] = useState(false);
    const [showCreate, setshowCreate] = useState(false);
    const [deleteEvent, setDeleteEvent] = useState<any>(undefined)
    const [showDelete, setShowDelete] = useState(false)

    const auth = getAuth()

    useEffect( () => {
        const fetchEvents = async () => {
            try {
                // get events reference
                const eventsRef = collection(db, 'events')
                
                //create query
                const q = query(eventsRef, orderBy('date', 'desc'))

                //execute query
                const querySnap = await getDocs(q)

                // const lastVisible = querySnap.docs[querySnap.docs.length - 1]

                // setLastFetchedEvents(lastVisible)

                let events:any = []

                querySnap.forEach( (doc) => {
                    return events.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setEvents(events)
                setLoading(false)

                // console.log(events)

            }catch(err){
                console.log(err)
            }
        }
        fetchEvents()
    },[showEdit, showCreate, showDelete])
    const handleCloseEdit = () => {
        setshowEdit(false)
    }
    const handleshowEdit = (event:any) => {
        setSelectedEvent(event)
        setImgLoc(event.data.storageRef)
        setshowEdit(true)
    }

    const handleCloseCreate = () => {
        setshowCreate(false)
    }
    const handleshowCreate = () => {
        // setSelectedEvent(event)
        setCreateEvent({
            'id': '', 
            'data': {
                'createdBy': '',
                'date': '',
                'formLink': '',
                'isFeatured': false,
                'imgUrl': '',
                'longDesc': '',
                'modifiedBy': '',
                'shortDesc': '',
                'title': '',
                'videoUrl': '',
                'contactPerson': '',
                'posterLink': '',
        }})
        // console.log(selectedEvent)
        setshowCreate(true)
    }


    const onSubmit = async (e:any) => {
        e.preventDefault()
    
        setLoading(true)
        let str:any
        // Store image in firebase
        const storeImage = async (image:any) => {
            return new Promise((resolve, reject) => {
            const storage = getStorage()
            const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
    
            const storageRef = ref(storage, 'images/' + fileName)
    
            const uploadTask = uploadBytesResumable(storageRef, image, image.type)
    
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log('Upload is ' + progress + '% done')
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused')
                    break
                  case 'running':
                    console.log('Upload is running')
                    break
                  default:
                    break
                }
              },
              (error) => {
                reject(error)
              },
              () => {
                // store image firestore location
                str = uploadTask.snapshot.ref.toString().split('/')

                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL)
                })
              }
            )
          })
        }
        let imageUrls:any

        if (selectedEvent.images){
            imageUrls = await Promise.all(
                [...selectedEvent.images].map((image) => storeImage(image))
              ).catch(() => {
                setLoading(false)
                toast.error('Images not uploaded')
                return
              })
        }


        const selectedEventCopy = {
            ...selectedEvent,
        }
        
        const getUsername = auth.currentUser?.email?.split('@')
        const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

        selectedEventCopy.data.imgUrl = !imageUrls ? selectedEventCopy.data.imgUrl : imageUrls
        selectedEventCopy.data.modifiedBy = getUsername![0]
        selectedEventCopy.data.storageRef = !imageUrls ? (selectedEvent.data.storageRef ? selectedEvent.data.storageRef : '') : str[str.length - 1]
        selectedEventCopy.data.formLink = withHttp(selectedEventCopy.data.formLink)
        delete selectedEventCopy.id
        delete selectedEventCopy.images

    
        // update listing
        const docRef = doc(db, 'events', selectedEvent.id)
        await updateDoc(docRef,selectedEventCopy.data).catch((err) => console.log(err))

        const storage = getStorage();

        // Create a reference to the file to delete
        const desertRef = ref(storage, `images/${imgLoc}`);
  
        // Delete the file
        imageUrls && await deleteObject(desertRef).catch((err) => console.log(err))

        setLoading(false)
        setshowEdit(false)

        toast.success('Event updated')
        // navigate(`/category/${formDataCopy.type}/${docRef.id}`)

      }


    const onSubmitCreate = async (e:any) => {
      e.preventDefault()

      let str
      setLoading(true)
  
      // Store image in firebase
      const storeImage = async (image:any) => {
          return new Promise((resolve, reject) => {
          const storage = getStorage()
          const fileName = `${auth.currentUser?.uid}-${image?.name}-${uuidv4()}`
  
          const storageRef = ref(storage, 'images/' + fileName)
  
          const uploadTask = uploadBytesResumable(storageRef, image, image.type)
  
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
                  break
                default:
                  break
              }
            },
            (error) => {
              reject(error)
            },
            () => {
              // store image firestore location
              str = uploadTask.snapshot.ref.toString().split('/')

              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL)
              })
            }
          )
        })
      }
      let imageUrls:any

      if (createEvent.images){
          imageUrls = await Promise.all(
              [...createEvent.images].map((image) => storeImage(image))
            ).catch(() => {
              setLoading(false)
              toast.error('Images not uploaded')
              return
            })
      }

      const createEventCopy = {
          ...createEvent,
      }
      
      const getUsername = auth.currentUser?.email?.split('@')
      const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

      createEventCopy.data.imgUrl = !imageUrls ? createEventCopy.data.imgUrl : imageUrls
      createEventCopy.data.modifiedBy = getUsername![0]
      createEventCopy.data.createdBy = getUsername![0]
      createEventCopy.data.storageRef = !imageUrls ? (createEvent.data.storageRef ? createEvent.data.storageRef : '') : str[str.length - 1]
      createEventCopy.data.formLink = withHttp(createEventCopy.data.formLink)
      // createEventCopy.id = uuidv4()
      delete createEventCopy.id 
      delete createEventCopy.images


      const docRef = await addDoc(collection(db, 'events'), createEventCopy.data)
      setLoading(false)
      setshowCreate(false)

      toast.success('Event added')
      // navigate(`/category/${formDataCopy.type}/${docRef.id}`)

    }
    
    const onMutate = (e:any) => {
    
      // Files
      if(e.target.files) {
          setSelectedEvent((prevState:any) => ({
          ...prevState,
          images: e.target.files
        }))
      }

      // Date
      if(e.target.id === 'date') {
          let dt = new Date(selectedEvent?.data.date)
          setSelectedEvent((prevState:any) => ({
          ...prevState,
          'data': {...prevState.data,[e.target.id]: dt},
        } ))
      }

      // checkbox
      if(e.target.id === 'isFeatured') {
          setSelectedEvent((prevState:any) => ({
          ...prevState,
          'data': {...prevState.data, [e.target.id]: e.target.checked},
        } ))
      }

      // Text/Boolean/Numbers
      if(!e.target.files && e.target.id !== 'isFeatured') {
          setSelectedEvent((prevState:any) => ({
          ...prevState,
          'data': {...prevState.data, [e.target.id]: e.target.value},
        } ))
      }    
    }

    const onMutateCreate = (e:any) => {
    
        // Files
        if(e.target.files) {
            setCreateEvent((prevState:any) => ({
            ...prevState,
            images: e.target.files
          }))
        }

        // Date
        if(e.target.id === 'date') {
            let dt = new Date(selectedEvent?.data.date)
            setCreateEvent((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data,[e.target.id]: dt},
          } ))
        }

        // checkbox
        if(e.target.id === 'isFeatured') {
          setCreateEvent((prevState:any) => ({
            ...prevState,
            'data': {...prevState.data, [e.target.id]: e.target.checked},
          } ))
        }

        // Text/Boolean/Numbers
        if(!e.target.files && e.target.id !== 'isFeatured') {
            setCreateEvent((prevState:any) => ({
              ...prevState,
              'data': {...prevState.data, [e.target.id]: e.target.value},
            } ))
          }

    }

    const handleCloseDelete = () => {
      setShowDelete(false)
    }

    const handleShowDelete = (event:any) => {
      setImgLoc(event?.data?.storageRef)
      setDeleteEvent(event)
      setShowDelete(true)
    }

    const onDelete = async (e?:any) =>{
      e.preventDefault()

      const storage = getStorage();

        // Create a reference to the file to delete
      const desertRef = ref(storage, `images/${imgLoc}`);
      // Delete the file
      await deleteObject(desertRef).catch((err) => console.log(err))

      await deleteDoc(doc(db, "events", deleteEvent.id));
      setShowDelete(false)
    }

    const handleFilterSearch = (e) => {
      e.preventDefault()

      setText(e.target.value)
      setClearFilter(false)
      const filtered = events.filter(obj => {
        return obj.data.title.match(e.target.value);
      });
      setFilteredEvents(filtered)
    }


    if(loading){
      return <Spinner/>
    }
  return (
    <>
        <Container>
            <Row>
                <Col>
                <InputGroup style={{justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                    <Form.Control
                    placeholder="Search title"
                    aria-label="Search title"
                    value={text}
                    onChange={handleFilterSearch}
                    />
                    <Button onClick={() => handleshowCreate()}  variant="outline-secondary" style={{padding: '7px', margin: '0'}}><Icon icon="icon-park-outline:add" className="text-primary" style={{fontSize:"25px"}}/></Button>
                </InputGroup>
                </Col>
            </Row>
            <Row style={{marginTop: '10px'}}>
                {clearFilter && events?.map( (event: any, key: any) => {
                    let eventDate = new Date(event.data.date)
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={event.data.imgUrl} height={200} width={350} style={{objectFit: 'cover'}}/>
                        <Card.Body>
                            {event.data.title && <Card.Title>{event.data.title}</Card.Title>}
                            {event.data.shortDesc && <Card.Text style={{margin: 0}}><b>Short Description:</b> {event.data.shortDesc}</Card.Text>}
                            {event.data.longDesc && <Card.Text ><b>Description:</b> {event.data.longDesc}</Card.Text>}
                            {event.data.formLink && <Card.Text style={{margin: 0}}><b>Form Link:</b> {event.data.formLink}</Card.Text>}
                            {event.data.date && <Card.Text><b>Event Date:</b> {eventDate.toDateString()}</Card.Text>}
                            {event.data.videoUrl && <Card.Text style={{margin: 0}}><b>Video Link:</b> {event.data.videoUrl}</Card.Text>}
                            {event.data.createdBy && <Card.Text style={{margin: 0}}><b>Created by:</b> {event.data.createdBy}</Card.Text>}
                            {event.data.modifiedBy && <Card.Text><b>Modified by:</b> {event.data.modifiedBy}</Card.Text>}
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                          <Button variant="primary" onClick={() => handleshowEdit(event)} >Edit</Button>
                          <Button variant="danger" onClick={() => handleShowDelete(event)} >Delete</Button>
                        </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
                })}

                {!clearFilter && filteredEvents?.map( (event: any, key: any) => {
                    let eventDate = new Date(event.data.date)
                    return <Col sm={12} md={4}>
                    <Card style={{ width: '100%' }} key={key}>
                        <Card.Img variant="top" src={event.data.imgUrl} height={200} width={350} style={{objectFit: 'cover'}}/>
                        <Card.Body>
                            {event.data.title && <Card.Title>{event.data.title}</Card.Title>}
                            {event.data.shortDesc && <Card.Text style={{margin: 0}}><b>Short Description:</b> {event.data.shortDesc}</Card.Text>}
                            {event.data.longDesc && <Card.Text ><b>Description:</b> {event.data.longDesc}</Card.Text>}
                            {event.data.formLink && <Card.Text style={{margin: 0}}><b>Form Link:</b> {event.data.formLink}</Card.Text>}
                            {event.data.date && <Card.Text><b>Event Date:</b> {eventDate.toDateString()}</Card.Text>}
                            {event.data.videoUrl && <Card.Text style={{margin: 0}}><b>Video Link:</b> {event.data.videoUrl}</Card.Text>}
                            {event.data.createdBy && <Card.Text style={{margin: 0}}><b>Created by:</b> {event.data.createdBy}</Card.Text>}
                            {event.data.modifiedBy && <Card.Text><b>Modified by:</b> {event.data.modifiedBy}</Card.Text>}
                        <Card.Body style={{justifyContent: 'center', display: 'flex'}}>
                          <Button variant="primary" onClick={() => handleshowEdit(event)} >Edit</Button>
                          <Button variant="danger" onClick={() => handleShowDelete(event)} >Delete</Button>
                        </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
                })}
                
            </Row>
        </Container>

        <Modal {...selectedEvent} show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card.Img variant="top" src={selectedEvent?.data.imgUrl} />
          <Form>
            <Form.Group className="mb-3" >
              <Form.Label>Event Cover Image</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutate}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="isFeatured">
              <Form.Check reverse
                type="switch"
                id="isFeatured"
                label="Featured Event?"
                defaultChecked={selectedEvent?.data.isFeatured}
                checked={selectedEvent?.data.isFeatured}
                onChange={onMutate}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={selectedEvent?.data.title}
                onChange={onMutate}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="shortDesc">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedEvent?.data?.shortDesc}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="longDesc"
            >
              <Form.Label>Long Description</Form.Label>
              <Form.Control as="textarea" rows={4} 
              value={selectedEvent?.data.longDesc}
              onChange={onMutate}
              required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLink">
                <Form.Label>Form Link</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedEvent?.data.formLink}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="date">
                <Form.Label>Event Date</Form.Label>
                <Form.Control
                    type="datetime-local"
                    value={selectedEvent?.data.date && selectedEvent?.data.date}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="contactPerson">
                <Form.Label>Contact Person</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedEvent?.data.contactPerson}
                    onChange={onMutate}
                    required
                />
            </Form.Group>
            <h5><em style={{color: '#ac0200'}}>Choose between the two either video or poster link</em></h5>
            <Form.Group className="mb-3" controlId="videoUrl">
                <Form.Label>Video Link</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedEvent?.data.videoUrl}
                    placeholder='Place make sure to paste the embeded src link to enable the video'
                    onChange={onMutate}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="posterLink">
                <Form.Label>Poster Link</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedEvent?.data.posterLink}
                    placeholder='Please make sure to to paste the embeded src link from facebook'
                    onChange={onMutate}
                    required
                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} >
            Save Changes
          </Button>
        </Modal.Footer>
        {loading && <Spinner/>}
      </Modal>


      <Modal {...createEvent} show={showCreate} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Event Cover Image</Form.Label>
              <Form.Control
                type='file'
                accept='.jpg,.png,.jpeg'
                max='1'
                // value={selectedEvent?.data.title}
                onChange={onMutateCreate}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="isFeatured">
              <Form.Check reverse
                type="switch"
                id="isFeatured"
                label="Featured Event?"
                defaultChecked={createEvent?.data.isFeatured}
                checked={createEvent?.data.isFeatured}
                onChange={onMutateCreate}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={createEvent?.data?.title}
                onChange={onMutateCreate}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="shortDesc">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                    type="text"
                    value={createEvent?.data?.shortDesc}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="longDesc"
            >
              <Form.Label>Long Description</Form.Label>
              <Form.Control as="textarea" rows={4} 
              value={createEvent?.data.longDesc}
              onChange={onMutateCreate}
              required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLink">
                <Form.Label>Form Link</Form.Label>
                <Form.Control
                    type="text"
                    value={createEvent?.data.formLink}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="date">
                <Form.Label>Event Date</Form.Label>
                <Form.Control
                    type="datetime-local"
                    value={createEvent?.data.date && createEvent?.data.date}
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="contactPerson">
                <Form.Label>Contact Person</Form.Label>
                <Form.Control
                    type="text"
                    value={createEvent?.data.contactPerson}
                    onChange={onMutate}
                    required
                />
            </Form.Group>

            <h5 ><em style={{color: '#ac0200'}}>Choose between the two either video or poster link</em></h5>
            <Form.Group className="mb-3" controlId="formLink">
                <Form.Label>Video Link</Form.Label>
                <Form.Control
                    type="text"
                    value={createEvent?.data.videoUrl}
                    placeholder='Place make sure to paste the embeded link to enable the video'
                    onChange={onMutateCreate}
                    required
                />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="posterLink">
                <Form.Label>Poster Link</Form.Label>
                <Form.Control
                    type="text"
                    value={createEvent?.data.posterLink}
                    placeholder='Please make sure to pase link with https://'
                    onChange={onMutate}
                    required
                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreate}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmitCreate} >
            Save Changes
          </Button>
        </Modal.Footer>
        {loading && <Spinner/>}
      </Modal>



      <Modal {...deleteEvent} show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Agency</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onDelete}>
        <Modal.Body>
            <p>Are you sure you want to delete <b>{deleteEvent?.data.title}</b></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" type='submit'>
            Delete
          </Button>
        </Modal.Footer>
        </Form>
        {loading && <Spinner/>}
      </Modal>
    </>
  )
}

export default Events