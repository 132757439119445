import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from "react-bootstrap";

import { Link  } from 'react-router-dom'
import Scrollspy from "react-scrollspy";

// // import images
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";
// import { ReactComponent as Tiktok } from "../assets/images/tiktok.svg"
import { ReactComponent as Tiktok2 } from "../assets/images/tiktok2.svg"

const Navbars = ({isDark}:any) => {

    const [isOpenMenu, setisOpenMenu] = useState<boolean>(false);
    const [navClass, setnavClass] = useState<string>("");
    // const location = useLocation()

    const toggle = () => setisOpenMenu(!isOpenMenu);
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("nav-sticky");
        } else {
            setnavClass("");
        }
    }

    const isDarkClass = isDark ? "navbar-light" : "";

    // const pathMatchRoute = (route?:any) => {
    //     console.log(route)
    //     if (route === location.pathname){
    //         return true
    //     }
    // }

    
    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg fixed-top " + isDarkClass + " sticky " + navClass} id="navbar">
                <Container>
                    <Link className="navbar-brand logo" to="/">
                        <img src={logoLight} alt="" className="logo-light" height="50" />
                        <img src={logoDark} alt="" className="logo-dark" height="50" />
                    </Link>
                    <Navbar.Toggle className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isOpenMenu} aria-label="Toggle navigation" onClick={toggle}>
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="collapse navbar-collapse" id="navbarSupportedContent" in={isOpenMenu}>

                        <Scrollspy
                            offset={-18}
                            items={[
                                "feature",
                                "about",
                                "contact",
                            ]}
                            currentClassName="active"
                            className="navbar-nav ms-auto mb-2 mb-lg-0"
                            id="mySidenav"
                        >
                                <li className="nav-item">
                                    <Nav.Link href="/feature" className='nav-link'>Feature</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="/#about" className='nav-link'>About</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="/#services" className='nav-link'>Services</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="/#contact" className='nav-link'>Contact</Nav.Link>
                                </li>
                            
                        </Scrollspy>
                        <Link to="/agency" className="btn btn-info btn-sm navbar-btn my-lg-0 my-2 " style={{backgroundColor:"white"}}>
                            <Tiktok2 style={{height:"35px", width: "auto"}} className='active'/>
                            {/* <p style={{display: 'flex', fontSize:"8px", fontWeight: 'bolder', justifyContent: 'start' }}>Official Partner</p>
                            <Icon icon="logos:tiktok" style={{color:"#000", fontSize:"20px", marginLeft: '12px'}}/> */}
                        </Link>

                    </Navbar.Collapse>
                </Container>
            </nav>

        </React.Fragment>
    )
                        }

export default Navbars